<template>
  <div>
    <h3 class="title-g">系统管理</h3>
    <el-tabs v-model="activeNameA">
      <el-tab-pane label="用户协议" name="agreements">
        <el-tabs v-model="activeNameB" @tab-click="handleClickagreement">
          <el-tab-pane label="景区商家使用协议" name="agreementSight">
            <h3 class="center-g">景区商家使用协议</h3>
          </el-tab-pane>
          <el-tab-pane label="用户使用协议" name="agreementUser">
            <h3 class="center-g">用户使用协议</h3>
          </el-tab-pane>
          <el-tab-pane label="隐私协议" name="agreementPrivacy">
            <h3 class="center-g">隐私协议十万八</h3>
          </el-tab-pane>
          <el-tab-pane label="自驾公约" name="agreementEvent">
            <h3 class="center-g">自驾公约</h3>
          </el-tab-pane>
          <el-tab-pane label="拼车公约" name="agreementRide">
            <h3 class="center-g">拼车公约</h3>
          </el-tab-pane>
          <el-tab-pane label="个人介绍说明" name="privateInfo">
            <h3 class="center-g">个人介绍说明</h3>
          </el-tab-pane>
          <div v-if="agreementEditShow">
            <el-input type="textarea" autosize v-model="sysParams[activeNameB]"></el-input>
            <br />
            <br />
            <el-button type="success" @click="saveAgreement">保存</el-button>
          </div>
          <div v-else>
            <div class="databox-g">
              <pre>{{ sysParams[activeNameB] }}</pre>
              <br />
              <el-button type="warning" @click="editAgreementFn">编辑</el-button>
            </div>
          </div>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane label="充值套餐" name="rechargePackage">
        <h3 class="title-g">
          当前套餐
          <el-button type="primary" @click="cashPackEdit(cashPackInput)" size="mini">添加</el-button>
        </h3>
        <table style="margin: 30px">
          <tr>
            <td>序号</td>
            <td>价格</td>
            <td>周期</td>
            <td>说明</td>
            <td>时间</td>
            <td>删除</td>
          </tr>
          <tr v-for="(item, i) in cashPack" :key="i">
            <td>{{ item.id }}</td>
            <td>{{ item.amount }} 元</td>
            <td>{{ item.duration }}{{ item.unitName }}</td>
            <td>{{ item.intro }}</td>
            <td>{{ item.genTime }}</td>
            <td>
              <el-button type="danger" size="mini" @click="cashPackDelet(item)">删除</el-button>
            </td>
          </tr>
        </table>
        <!-- 套餐编辑对话框 -->
        <el-dialog :visible.sync="cashPackShow">
          <el-form :model="cashPackInput" style="margin: 30px">
            <el-form-item label="金额">
              <el-input v-model="cashPackInput.amount" type="number" placeholder="会员价格"></el-input>
            </el-form-item>
            <el-form-item label="时间期数量">
              <el-input v-model="cashPackInput.duration" type="number" placeholder="时间期"></el-input>
            </el-form-item>
            <el-form-item label="时间期单位">
              <el-select v-model="cashPackInput.unit" placeholder="请选择">
                <el-option label="天" value="1"></el-option>
                <el-option label="周" value="2"></el-option>
                <el-option label="月" value="3"></el-option>
                <el-option label="年" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="说明">
              <el-input
                v-model="cashPackInput.intro"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                placeholder="说明"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="cashPackSubmit">确定</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="公司信息" name="params">
        <el-form
          :model="sysParams"
          :rules="rules"
          ref="sysParams"
          label-width="120px"
          style="margin: 30px;"
        >
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="sysParams.companyName"></el-input>
          </el-form-item>
          <el-form-item label="统一代码" prop="companyCode">
            <el-input v-model="sysParams.companyCode"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="regAddress">
            <el-input v-model="sysParams.regAddress"></el-input>
          </el-form-item>
          <el-form-item label="银行账号" prop="bankAccount">
            <el-input v-model="sysParams.bankAccount"></el-input>
          </el-form-item>
          <el-form-item label="开户行" prop="bankName">
            <el-input v-model="sysParams.bankName"></el-input>
          </el-form-item>
          <el-form-item label="邮寄地址" prop="mailAddress">
            <el-input v-model="sysParams.mailAddress"></el-input>
          </el-form-item>
          <el-form-item label="收件人" prop="receiptor">
            <el-input v-model="sysParams.receiptor"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="tel">
            <el-input v-model="sysParams.tel"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="sysParams.email"></el-input>
          </el-form-item>
          <el-form-item label="网址" prop="website">
            <el-input v-model="sysParams.website"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveCompInfo('sysParams')">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  getCashPackList,
  deteleCashPack,
  addCashPack,
  getSystemInfo,
  editAgreement,
  editCompanyInfo,
} from "@/api/api";

export default {
  data() {
    return {
      activeNameA: "agreements",
      activeNameB: "agreementSight",
      cashPackShow: false,
      agreementEditShow: false,
      cashPackInput: {
        id: "",
        amount: "",
        duration: "",
        unit: "4",
        intro: ""
      },
      rules: {
        companyName: [{ required: true, message: "必填", trigger: "blur" }],
        companyCode: [{ required: true, message: "必填", trigger: "blur" }],
        regAddress: [{ required: true, message: "必填", trigger: "blur" }],
        bankAccount: [{ required: true, message: "必填", trigger: "blur" }],
        bankName: [{ required: true, message: "必填", trigger: "blur" }],
        mailAddress: [{ required: true, message: "必填", trigger: "blur" }],
        receiptor: [{ required: true, message: "必填", trigger: "blur" }],
        tel: [{ required: true, message: "必填", trigger: "blur" }],
        telBiz: [{ required: true, message: "必填", trigger: "blur" }],
        email: [{ required: true, message: "必填", trigger: "blur" }],
        website: [{ required: true, message: "必填", trigger: "blur" }],
      },
      sysParams: {},
      cashPack: []
    };
  },
  mounted() {
    this.getCashPackListFn();
    this.getSystemInfoFn();
  },
  methods: {
    getSystemInfoFn() {
      const that = this;
      getSystemInfo().then(res => {
        if (res.result) {
          that.sysParams = res.data;
        }
      });
    },
    getCashPackListFn() {
      const that = this;
      getCashPackList().then(res => {
        if (res.result) {
          that.cashPack = res.data;
        }
      });
    },
    cashPackSubmit() {
      const that = this;
      //添加充值套餐
      addCashPack(this.cashPackInput).then(res => {
        if (res.result) {
          that.getCashPackListFn();
        }
      });
      this.cashPackInput = {
        id: "",
        cashAmount: "",
        donate: ""
      };
      this.cashPackShow = false;
    },
    cashPackEdit(item) {
      //编辑充值套餐
      this.cashPackInput = { ...item };
      this.cashPackShow = true;
    },
    cashPackDelet(item) {
      const that = this;
      //删除充值套餐
      this.$confirm("确认关闭？")
        .then(() => {
          deteleCashPack(item).then(res => {
            if (res.result) {
              that.getCashPackListFn();
            }
          });
        })
        .catch();
    },
    editAgreementFn() {
      //编辑使用协议
      this.agreementEditShow = true;
    },
    saveAgreement() {
      let data = {
        key: this.activeNameB,
        value: this.sysParams[this.activeNameB]
      };
      editAgreement(data).then(res => {
        if (res.result) {
          this.$message.success("修改成功");
        }
      });
      //保存使用协议
      this.agreementEditShow = false;
    },
    handleClickagreement() {
      this.agreementEditShow = false;
    },

    saveCompInfo(formName) {
      const that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          editCompanyInfo(this.sysParams).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "修改成功!"
              });
              that.getSystemInfoFn();
            }
          });
        } else {
          that.$alert("所有项均为必填", {
            center: true
          });
          return false;
        }
      });
    },
  }
};
</script>

<style scoped>
.imgwrap {
  width: 300px;
  box-shadow: 0 0 5px #bbb;
}

input {
  width: 100px;
  margin-right: 50px;
}

.exp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 10px;
  line-height: 30px;
}
</style>